<script>


/**
 * Basic Info Edit component
 */

 import flatPickr from "vue-flatpickr-component";
 import "flatpickr/dist/flatpickr.css";

 import {
  required,
} from "vuelidate/lib/validators";




export default {

    emits: ['confirm', 'cancel'],

    props: ['listing'],
    components: {
        flatPickr
    },

    validations: {
        listing: {
            start_date: { required },
            end_date: { required },
            listing_price: { required },
        },
    },

    

    data() {
      
        return {
            submitted : false,
            
        }
       
    },
    methods: {
        formSubmit() {
            this.submitted = true;     
            this.$v.$touch();     
            if (this.$v.$error == false) {     
                this.$emit('confirm', this.listing)
            }
        },
    },

    created() {
     

    },

    mounted() {
    }

};
</script>

<template>
    <div class="card">
        <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Basic Information</h4>
        </div>
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Listing Price</label>
                            <input id="validationCustom01" v-model="listing.listing_price" type="text"
                                class="form-control" placeholder="$" value="Mark" :class="{
                                    'is-invalid': submitted && $v.listing.listing_price.$error,
                                }" />
                            <div v-if="submitted && $v.listing.listing_price.$error" class="invalid-feedback">
                                <span v-if="!$v.listing.listing_price.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom03">Start Date</label>
                            <flat-pickr
                                v-model="listing.start_date"
                                placeholder="Select a date"
                                class="form-control"
                                :class="{
                                    'is-invalid': submitted && $v.listing.start_date.$error,
                                }"
                                ></flat-pickr>
                            <div v-if="submitted && $v.listing.start_date.$error" class="invalid-feedback">
                                <span v-if="!$v.listing.start_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom04">End Date</label>
                            <flat-pickr
                                v-model="listing.end_date"
                                placeholder="Select a date"
                                class="form-control"
                                :class="{
                                    'is-invalid': submitted && $v.listing.end_date.$error,
                                }"
                                ></flat-pickr>
                        
                            <div v-if="submitted && $v.listing.end_date.$error" class="invalid-feedback">
                                <span v-if="!$v.listing.end_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>