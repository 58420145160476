<script>


/**
 * Listng Action Update component
 */



import Choices from "choices.js";


export default {

    emits: ['confirm', 'cancel'],

    props: ['listing_action'],
    components: {
        
    },
 

    data() {
      
        return {
            submitted : false,
            action : this.listing_action
        }
       
    },
    methods: {
        formSubmit() {
            this.submitted = true;  
            console.log("new action:", this.action)   
            this.$emit('confirm', this.action)
            
        },
    },

    created() {
       
    },

    mounted() {
        new Choices("#choices-listing-action", {
            searchEnabled: false,
            removeItemButton: true,
            choices: [
                {
                value: "NEW",
                label: "NEW",
                selected: this.listing_action == 'NEW' ? true : false,
                },
                {
                value: "UPDATE",
                label: "UPDATE",
                selected: this.listing_action == 'UPDATE' ? true : false,
                },
                {
                value: "CANCEL",
                label: "CANCEL",
                selected: this.listing_action == 'CANCEL' ? true : false,
                },
                {
                value: "TERMINATE",
                label: "TERMINATE",
                selected: this.listing_action == 'TERMINATE' ? true : false,
                },
                {
                value: "RELIST",
                label: "RELIST",
                selected: this.listing_action == 'RELIST' ? true : false,
                },
            ],
            })


    }

};
</script>

<template>
    <div class="card">
        <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Listing Operation Update</h4>
        </div>
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom02">New Operation</label>
                            <select
                                class="form-control"
                                name="choices-listing-action"
                                id="choices-listing-action"
                                v-model="action"
                                >
                                </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="mb-3">
                            
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>