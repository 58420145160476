<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import FolderTree from "@/components/folder-tree"

import SellerDialog from './components/seller_edit_dialog'
import PropertyDialog from './components/property_edit_dialog'
import ActionEditDialog from './components/action_edit_dialog'
import StatusEditDialog from './components/status_edit_dialog'
import RejectDialog from './components/reject_dialog'
import BascInfoEditDialog from './components/basic_info_edit_dialog'

import appConfig from "@/app.config";
import {getListingApi} from '@/api/listing'
import {uploadFile} from '@/api/misc'

/**
 * Project-overview component
 */
export default {
  page: {
    title: "Listing Overview",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Listing Overview",
      items: [
        {
          text: "Listing",
          href: "/listing/pending_list"
        },
        {
          text: "Overview",
          active: true,
        },
      ],

      listing_status : '',
      listing: {},

      basic : {
        listing_price : 0,
        start_date : '',
        end_date: '',
      },

      seller: {
        seller_first_name: "",
        seller_last_name: "",
        city_id: "",
        province_id: "",
        address: "",
        unit: "",
        phone: "",
        is_cooperation : 0,
      },

      property : {
        pid1 : "",
        pid2 : "",
        pid3 : "",
        city_id: "",
        province_id: "",
        address: "",
        unit: "",
        description: ""
      },


    };
  },
  components: {
    Layout,
    PageHeader,
    FolderTree,
    SellerDialog,
    PropertyDialog,
    ActionEditDialog,
    StatusEditDialog,
    RejectDialog,
    BascInfoEditDialog,
    
  },

  methods: {
    /**
     * street view data update
     */
    fileDownload(f) {
      window.open(f.file_url, '_blank');
    },

    fileUpload(f) {
      let formData = new FormData()
      formData.append("file", f)
      formData.append("folder_id", f.folder_id)
      uploadFile(formData).then((res)=>{
        
        if (res[0].errCode == 200) {
            let file_data = {
              listing_id : this.listing.agent_listing_id,
              file : {
                folder_id : f.folder_id,
                file_name : res[0].response.filename,
                save_route_key : res[0].response.key,
                save_route: res[0].response.url,
              }
            }
            getListingApi().add_file(file_data).then((res)=>{
              if (res.errCode == 0) {
                let folder = this.listing.folders.find((fo)=>fo.id == f.folder_id)
                if (!folder.files)  {
                  folder.files = []
                }
                folder.files.push(res.data.file)
              } else {
                this.$alertify.error("Save File Failed");
              }
            })
        } else {
          console.log(res)
          this.$alertify.error("Upload File failed");
        }
      })
     
    }, 

    remove_property(p) {
      getListingApi().delete_property({property_id: p.id}).then((res) => {
        if (res.errCode == 0) {
           this.listing.plist = this.listing.plist.filter((e) => e.id != p.id)
        } else {
          this.$alertify.error("Remove Property Failed");
        }
      })
      
    },
    remove_seller(s) {
      getListingApi().delete_seller({seller_id: s.id}).then((res) => {
        if (res.errCode == 0) {
           this.listing.sellers = this.listing.sellers.filter((e) => e.id != s.id)
        } else {
          this.$alertify.error("Remove Seller Failed");
        }
      })
    },

    seller_dialog_closed() {
      this.$bvModal.hide('modal-center')
    },

    sellerSave() {
      let data = {
        "listing_id" : this.listing.agent_listing_id,
        "seller" : this.seller
      }
      getListingApi().add_seller(data).then((res)=>{
        if (res.errCode == 0) {
          this.seller.id = res.data.seller.id
          this.listing.sellers.push(this.seller)
          this.seller = {
            seller_first_name: "",
            seller_last_name: "",
            city_id: "",
            province_id: "",
            address: "",
            unit: "",
            phone: "",
          }
          this.$bvModal.hide('modal-center')
        } else {
          this.$alertify.error("Add Seller Failed");
        }
        
      })
      
    },

    property_dialog_closed() {
      this.$bvModal.hide('modal-property')
    },
    property_save() {
      this.property.pid = `${this.property.pid1}-${this.property.pid2}-${this.property.pid3}`
      let data = {
        "listing_id" : this.listing.agent_listing_id,
        "property" : this.property
      }
      getListingApi().add_property(data).then((res)=>{
        if (res.errCode == 0) {
          this.property.id = res.data.property.id
          this.listing.plist.push(this.property)
          this.property = {
            city_id: "",
            province_id: "",
            address: "",
            unit: "",
            pid1 : "",
            pid2 : "",
            pid3 : "",
            description : "",
          }
          this.$bvModal.hide('modal-property')
        } else {
          this.$alertify.error("Add Property Failed");
        }
        
      })

    },


    listing_start_process() {
      getListingApi().listing_start_process({listing_id : this.listing.agent_listing_id}).then((res)=> {
        if (res.errCode == 0) {
          this.listing_status = res.data.status
          this.listing.status = res.data.status
          this.$alertify.message("Start to Process");
        } else {
          this.$alertify.message("Failed to Process " + res.errCode);
        }
      })
    },

    listing_finish_process() {
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Approve this Listing?",
        () => {
          getListingApi().listing_finish_process({listing_id : this.listing.agent_listing_id}).then((res)=> {
            if (res.errCode == 0) {
              this.listing_status = res.data.status
              this.listing.status = res.data.status
              this.$alertify.message("Finish Processing");
            }else {
              this.$alertify.message("Failed to Process " + res.errCode);
            }
          })
        },
        () => {
          
        }
      );
     
    },


    action_edit_dialog_closed() {
      this.$bvModal.hide('modal-edit-action-dialog')
    },


    listing_action_save(new_action) {
      getListingApi().update_listing_status_action({listing_id : this.listing.agent_listing_id, action: new_action}).then((res)=> {
        if (res.errCode == 0) {
          this.listing.listing_action =new_action
          this.$alertify.message("Update Success ");
          this.$bvModal.hide('modal-edit-action-dialog')
        }else {
          this.$alertify.message("Failed to Update " + res.errCode);
        }
      })
      
     
    },


    status_edit_dialog_closed() {
      this.$bvModal.hide('modal-edit-status-dialog')
    },


    listing_status_save(new_status) {
      getListingApi().update_listing_status_action({listing_id : this.listing.agent_listing_id, status: new_status}).then((res)=> {
        if (res.errCode == 0) {
          this.listing.status = new_status
          this.listing_status = new_status
          this.$alertify.message("Update Success ");
          this.$bvModal.hide('modal-edit-status-dialog')
        }else {
          this.$alertify.message("Failed to Update " + res.errCode);
        }
      })
    },


    reject_dialog_closed() {
      this.$bvModal.hide('modal-reject-dialog')
    },


    listing_reject(reasons) {
      getListingApi().reject_listing({listing_id : this.listing.agent_listing_id, reasons: reasons}).then((res)=> {
        if (res.errCode == 0) {
          this.listing.status = 'REJECTED'
          this.listing_status = 'REJECTED'
          this.$alertify.message("Rejected ");
          this.$bvModal.hide('modal-reject-dialog')
        }else {
          this.$alertify.message("Failed to Reject " + res.errCode);
        }
      })
    },

    basic_info_dialog_closed() {
      this.$bvModal.hide('modal-basic-info-edit-dialog')
    },


    listing_save() {
      this.basic.listing_id = this.listing.agent_listing_id
      getListingApi().update_listing_basic_info(this.basic).then((res)=> {
        if (res.errCode == 0) {
          this.listing.listing_price = this.basic.listing_price
          this.listing.listing_effective_date = this.basic.start_date
          this.listing.listing_expiry_date = this.basic.end_date
          this.$alertify.message("Update Success ");
          this.$bvModal.hide('modal-basic-info-edit-dialog')
        }else {
          this.$alertify.message("Failed to Update " + res.errCode);
        }
      })
    },

    listing_sign() {

      let data ={listing_id : this.listing.agent_listing_id}
      getListingApi().sign_listing(data).then((res)=> {
        if (res.errCode == 0) {
          this.listing.is_signed = 1
          this.$alertify.message("Signed Success "); 
        }else {
          this.$alertify.message("Failed to Sign " + res.errCode);
        }
      })

      
    }



  },

  created() {


    getListingApi().detail({listing_id: this.$route.query.listing_id}).then((res) =>{
       if (res.errCode == 0) {
        this.listing = res.data
        this.listing_status = this.listing.status
        this.basic = {
          listing_price : this.listing.listing_price,
          start_date : this.listing.listing_effective_date,
          end_date : this.listing.listing_expiry_date
        }
       }
    })

  },
  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body pb-5">
            <div class="row">
              <div class="col">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="avatar">
                      <div
                        class="avatar-title bg-soft-primary text-primary font-size-18 rounded"
                      >
                      
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <div class="text-muted">
                      <h5 class="font-size-16 mb-1">{{ listing.agent_listing_id }}</h5>
                      <p class="mb-0" v-for="p in listing.plist" :key="p.id">
                        {{ `${p.unit} ${p.address} ${p.city_name} ${p.postal_code}` }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->

              <div class="col-auto">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3"  >

                  <button type="button" class="btn btn-light"  :disabled="listing.is_signed == 1 "  @click="listing_sign">Sign</button>
                  <button type="button" class="btn btn-light"  :disabled="!(listing_status == 'SUBMITTED') "  @click="listing_start_process()">Process</button>
                  <button type="button" class="btn btn-light"  :disabled="!(listing_status == 'PROCESSING') "  v-b-modal.modal-reject-dialog>Reject</button>
                  <button type="button" class="btn btn-light"  :disabled="!(listing_status == 'PROCESSING') " @click="listing_finish_process()">Approve</button>
                 
                 
                  <b-dropdown
                    variant="link"
                    toggle-class="shadow-none text-dark"
                    right
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <li><b-dropdown-item  v-b-modal.modal-basic-info-edit-dialog> Edit Basic Info</b-dropdown-item></li>
                    <li><b-dropdown-item  v-b-modal.modal-edit-action-dialog>Force Edit Operation</b-dropdown-item></li>
                    <li><b-dropdown-item  v-b-modal.modal-edit-status-dialog>Force Edit Status </b-dropdown-item></li>
                  </b-dropdown>
                   
                  <b-modal centerd id="modal-basic-info-edit-dialog" title="Basic Info" size="lg" hide-footer>
                    <BascInfoEditDialog :listing="basic" @cancel="basic_info_dialog_closed()" @confirm="listing_save"  />
                  </b-modal>
                  <b-modal centered id="modal-reject-dialog" title="Reject"  size="xl" hide-footer>
                    <RejectDialog  @cancel="reject_dialog_closed()" @confirm="listing_reject" />
                  </b-modal>
                  <b-modal centered id="modal-edit-action-dialog" title="Update Operation"  size="lg" hide-footer>
                    <ActionEditDialog :listing_action="listing.listing_action"  @cancel="action_edit_dialog_closed()" @confirm="listing_action_save" />
                  </b-modal>
                  <b-modal centered id="modal-edit-status-dialog" title="Update Status"  size="lg" hide-footer>
                    <StatusEditDialog :listing_status="listing.status"  @cancel="status_edit_dialog_closed()" @confirm="listing_status_save" />
                  </b-modal>

                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-lg-5">
                <div class="mt-3">
                  <ul class="text-muted">
                    <li class="py-1">{{ listing.agent_type == 1? 'Primary Listing Agent' : 'Co Listing Agent' }}</li>
                    <li class="py-1">{{ listing.display_listing_type }}</li>
                    <li class="py-1">${{ Number(listing.listing_price).toLocaleString() }}</li>
                    <li class="py-1">Doc Signed : {{ listing.is_signed == 1 ? 'Yes': 'No' }}</li>
                  </ul>
                </div>
              </div>
              <!-- end col -->

              <div class="col-lg-7">
                <div class="row">
                  <div class="col-lg-4 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i
                          class="uil uil-check-circle text-primary font-size-22"
                        ></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Operation</h5>
                        <p class="text-muted mb-0" v-if="listing.listing_action == 'NEW'"> New Listing</p>
                        <p class="text-muted mb-0" v-if="listing.listing_action == 'CANCEL'">Listing Cancel</p>
                        <p class="text-muted mb-0" v-if="listing.listing_action == 'UPDATE'"> Listing Update</p>
                        <p class="text-muted mb-0" v-if="listing.listing_action == 'TERMINATE'">Listing Termination</p>
                        <p class="text-muted mb-0" v-if="listing.listing_action == 'RELIST'">Re-List</p>

                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i
                          class="uil uil-check-circle text-primary font-size-22"
                        ></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Status</h5>
                        <p class="text-muted mb-0">{{listing.status}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-lg-4 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i
                          class="uil uil-calendar-alt text-primary font-size-22"
                        ></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Start Date</h5>
                        <p class="text-muted mb-0">{{ listing.listing_effective_date }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->

                  <div class="col-lg-4 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                      <div class="flex-shrink-0 me-3">
                        <i
                          class="uil uil-calendar-alt text-primary font-size-22"
                        ></i>
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-size-12 mb-1">Expiry Date</h5>
                        <p class="text-muted mb-0">{{listing.listing_expiry_date}}</p>
                      </div>
                    </div>
                  </div>
                   <!-- end col -->

                </div>
                <!-- end row -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- en card -->
        <b-tabs
          class="mt-n5 pt-2"
          content-class="card card-body mt-3"
          nav-class="nav-tabs-custom"
          nav-wrapper-class="nav-tabs-custom"
        >
          <b-tab title="Overview" active>
            <div class="row">
              <div class="col-xl-6 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                            <i class="uil uil-list-ul"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          Property
                        </p>
                        <h6 class="font-size-12 mb-0" v-for="(p,idx) in listing.plist" :key="p.id+'_'+idx">{{ p.pid }} {{ `${p.unit} ${p.address} ${p.city_name} ${p.postal_code}`}}</h6>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
              <div class="col-xl-6 col-sm-6">
                <div class="card border shadow-none">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                          <div
                            class="avatar-title bg-light text-primary rounded-circle font-size-18"
                          >
                            <i class="uil uil-check-circle"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="mb-1 text-truncate text-muted">
                          Seller
                        </p>
                        <h6 class="font-size-12 mb-0" v-for="(s,idx) in listing.sellers" :key="s.id+'_'+idx">{{ `${s.seller_first_name} ${s.seller_last_name}` }} {{ `${s.unit} ${s.address} ${s.city_name} ${s.postal_code}`}} {{ s.phone }}</h6>
                      </div>
                    </div>
                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->


            </div>
            <!-- end row -->

            <div class="row">
              <div class="col-lg-24">
                <div class="card border shadow-none h-100 mb-lg-0">
                  <div class="card-body">
                    <h5 class="card-title mb-4">Commission</h5>

                    <!--  Gross Commission-->
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <P>Total Brokerage Commission:</P>
                        <div class="page-title-right">
                          ${{ (listing.total_gross_commission + listing.total_gst).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>{{listing.gross_brokerage_commission}}% on First 100K + {{listing.gross_brokerage_commission2}}% on Balance+ C${{ Number(listing.gross_brokerage_commission_bonus).toLocaleString() }} Bonus</p>
                        <div class="page-title-right">${{ Number(listing.total_gross_commission).toLocaleString() }} </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>GST</p>
                        <div class="page-title-right">${{ Number(listing.total_gst).toLocaleString() }} </div>
                      </div>
                    </div>


                     <!-- Listing Brokerage Commission-->
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <P>Listing Brokerage Commission:</P>
                        <div class="page-title-right">
                          ${{ (listing.total_listing_commission + listing.total_gst).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>{{listing.listing_brokerage_commission}}% on First 100K + {{listing.listing_brokerage_commission2}}% on Balance+ C${{ Number(listing.listing_brokerage_commission_bonus).toLocaleString() }} Bonus</p>
                        <div class="page-title-right">${{ Number(listing.total_listing_commission).toLocaleString() }} </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>GST</p>
                        <div class="page-title-right">${{ Number(listing.listing_gst).toLocaleString() }} </div>
                      </div>
                    </div>

                    <!-- Cooperating Brokerage Commission-->
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <P>Cooperating Brokerage Commission:</P>
                        <div class="page-title-right">
                          ${{ (listing.total_cooperating_commission + listing.cooperating_gst).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>{{listing.cooperating_brokerage_commission}}% on First 100K + {{listing.cooperating_brokerage_commission2}}% on Balance+ C${{ Number(listing.cooperating_brokerage_commission_bonus).toLocaleString() }} Bonus</p>
                        <div class="page-title-right">${{ Number(listing.total_cooperating_commission).toLocaleString() }} </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>GST</p>
                        <div class="page-title-right">${{ Number(listing.cooperating_gst).toLocaleString() }} </div>
                      </div>
                    </div>


                    <!-- Listing Only Brokerage  Commission-->
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <P>Listing Only Brokerage Commission:</P>
                        <div class="page-title-right">
                          ${{ (listing.total_listing_only_commission + listing.listing_only_gst).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>{{listing.listing_only_brokerage_commission}}% on First 100K + {{listing.listing_only_brokerage_commission2}}% on Balance+ C${{ Number(listing.listing_only_brokerage_commission_bonus).toLocaleString() }} Bonus</p>
                        <div class="page-title-right">${{ Number(listing.total_listing_only_commission).toLocaleString() }} </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>GST</p>
                        <div class="page-title-right">${{ Number(listing.listing_only_gst).toLocaleString() }} </div>
                      </div>
                    </div>

                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </b-tab>
          <b-tab title="Property">
            <div class="d-flex align-items-start">
              <div class="flex-grow-1">
                <h5 class="card-title">Property</h5>
              </div>

              
              <div class="d-flex flex-wrap align-items-start justify-content-end gap-2 mb-3">

                <div>
                    <button
                      type="button"
                      class="btn btn-light" 
                      data-bs-toggle="modal"
                      v-b-modal.modal-property
                    >Add New Property
                    </button>
 
                    <b-modal centered id="modal-property" title="New Property"  size="lg" hide-footer>
                      <PropertyDialog :property="property"  @cancel="property_dialog_closed()" @confirm="property_save" />
                    </b-modal>
                  </div>
              </div>
            </div>

            <div data-simplebar >
              <div class="table-responsive" style="min-height: 325px;">

                <table class="table align-middle table-nowrap mb-0" style="height:100%">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 40px;"></th>
                      <th scope="col" style="width: 140px;">PID</th>
                      <th scope="col">Address</th>
                      <th scope="col" style="width: 140px;">Action</th>
                    </tr>
                    <!-- end tr -->
                  </thead>
                  <!-- end thead -->
                  <tbody>
                    <tr v-for="(p, idx) in listing.plist" :key="p+'_'+idx">
                       <td>
                        <div class="form-check font-size-16">
                          <label
                            class="form-check-label"
                            for="taskcheck01"
                          ></label>
                        </div>
                      </td>
                      <td><a href="#">{{ `${p.pid}`}}</a></td>
                      <td>
                        <h5 class="font-size-14">
                          {{ `${p.unit} ${p.address} ${p.city_name} ${p.postal_code}`}}
                        </h5>
                      </td>
                      <td>
                        <b-dropdown
                          variant="white"
                          toggle-class="font-size-16 px-2 text-muted p-0"
                          right
                        >
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-h"></i>
                          </template>
                          <b-dropdown-item @click="edit_property(p)" >Edit</b-dropdown-item>
                          <b-dropdown-item @click="remove_property(p)">Remove</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <!-- end tr -->
                  
                  </tbody>
                  <!-- end tbody -->
                </table>
                <!-- end table -->
              </div>
            </div>
          </b-tab>
          <b-tab title="Seller">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Seller
                    <span class="text-muted fw-normal ms-2"></span>
                  </h5>
                </div>
              </div>

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-end gap-2 mb-3"
                >
                  <div>
                    <button
                      type="button"
                      class="btn btn-light" 
                      data-bs-toggle="modal"
                      v-b-modal.modal-center
                    >Add New Seller
                    </button>
 
                    <b-modal centered id="modal-center" title="New Seller"  size="lg" hide-footer>
                      <SellerDialog :seller="seller"  @cancel="seller_dialog_closed()" @confirm="sellerSave" />
                    </b-modal>
                  </div>

                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="row">
              <div class="col-xl-4 col-sm-6" v-for="(seller, idx) in listing.sellers" :key="seller.id+'_'+idx">
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 avatar rounded-circle me-3">
                        <img
                          src="@/assets/images/users/avatar-1.jpg"
                          alt=""
                          class="img-fluid rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <a href="#" class="text-dark" v-if="seller.is_cooperation == 0">{{`${seller.seller_first_name} ${seller.seller_last_name}` }}</a>
                          <a href="#" class="text-dark" v-if="seller.is_cooperation == 1">{{`${seller.name}` }}</a>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          Address:{{`${seller.address}` }}
                        </p>
                        <p class="text-muted text-truncate mb-0">
                          Phone:{{`${seller.phone}` }}
                        </p>
                        <p class="text-muted text-truncate mb-0">
                          Email:{{`${seller.email}` }}
                        </p>
                      </div>
                      <b-dropdown
                        class="flex-shrink-0"
                        toggle-class="text-body font-size-16 p-0"
                        right
                        variant="white"
                      >
                        <template v-slot:button-content>
                          <feather
                            class="icon-xs"
                            type="more-horizontal"
                          ></feather>
                        </template>
                        <b-dropdown-item @click="edit_seller(seller)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="remove_seller(seller)">Remove</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->


            </div>
            <!-- end row -->
          </b-tab>

          <b-tab title="Files">
            <FolderTree :title="title" :folders="listing.folders" @fileDownload="fileDownload" @fileUpload="fileUpload"/>
          </b-tab>

          <b-tab title="Commission">
            <div class="row">
              <div class="col-lg-24">
                <div class="card border shadow-none h-100 mb-lg-0">
                  <div class="card-body">
                    <h5 class="card-title mb-4">Commission</h5>

                    <!--  Gross Commission-->
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <P>Total Brokerage Commission:</P>
                        <div class="page-title-right">
                          ${{ (listing.total_gross_commission + listing.total_gst).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>{{listing.gross_brokerage_commission}}% on First 100K + {{listing.gross_brokerage_commission2}}% on Balance+ C${{ Number(listing.gross_brokerage_commission_bonus).toLocaleString() }} Bonus</p>
                        <div class="page-title-right">${{ Number(listing.total_gross_commission).toLocaleString() }} </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>GST</p>
                        <div class="page-title-right">${{ Number(listing.total_gst).toLocaleString() }} </div>
                      </div>
                    </div>


                     <!-- Listing Brokerage Commission-->
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <P>Listing Brokerage Commission:</P>
                        <div class="page-title-right">
                          ${{ (listing.total_listing_commission + listing.listing_gst).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>{{listing.listing_brokerage_commission}}% on First 100K + {{listing.listing_brokerage_commission2}}% on Balance+ C${{ Number(listing.listing_brokerage_commission_bonus).toLocaleString() }} Bonus</p>
                        <div class="page-title-right">${{ Number(listing.total_listing_commission).toLocaleString() }} </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>GST</p>
                        <div class="page-title-right">${{ Number(listing.listing_gst).toLocaleString() }} </div>
                      </div>
                    </div>

                    <!-- Cooperating Brokerage Commission-->
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <P>Cooperating Brokerage Commission:</P>
                        <div class="page-title-right">
                          ${{ (listing.total_cooperating_commission + listing.cooperating_gst).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>{{listing.cooperating_brokerage_commission}}% on First 100K + {{listing.cooperating_brokerage_commission2}}% on Balance+ C${{ Number(listing.cooperating_brokerage_commission_bonus).toLocaleString() }} Bonus</p>
                        <div class="page-title-right">${{ Number(listing.total_cooperating_commission).toLocaleString() }} </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>GST</p>
                        <div class="page-title-right">${{ Number(listing.cooperating_gst).toLocaleString() }} </div>
                      </div>
                    </div>


                    <!-- Listing Only Brokerage  Commission-->
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <P>Listing Only Brokerage Commission:</P>
                        <div class="page-title-right">
                          ${{ (listing.total_listing_only_commission + listing.listing_only_gst).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>{{listing.listing_only_brokerage_commission}}% on First 100K + {{listing.listing_only_brokerage_commission2}}% on Balance+ C${{ Number(listing.listing_only_brokerage_commission_bonus).toLocaleString() }} Bonus</p>
                        <div class="page-title-right">${{ Number(listing.total_listing_only_commission).toLocaleString() }} </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex align-items-center justify-content-between">
                        <p>GST</p>
                        <div class="page-title-right">${{ Number(listing.listing_only_gst).toLocaleString() }} </div>
                      </div>
                    </div>

                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </b-tab>

          <b-tab title="State Log">
            <div class="card-header">
              <h5 class="card-title mb-0">State Log</h5>
            </div>
            <div class="card-body">
              <div data-simplebar style="max-height: 280px;">
                <div class="list-group list-group-flush">

                  <div class="list-group-item py-3" v-for="(item, idx) in listing.state_change_logs" :key="item.id+'_'+idx">
                    <div class="d-flex aligm-items-start">
                      <div class="flex-shrink-0 me-3">
                        <img
                          :src="item.operator.avatar"
                          alt=""
                          class="avatar-sm rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-13 mb-1 text-truncate">
                          <a href="javascript: void(0);" class="text-dark"
                            >{{item.operator.name}}</a
                          >
                          <small class="text-muted ms-1">{{ item.change_time }}</small>
                        </h5>
                        <span class="text-muted mb-0"  v-for="(str,idx) in item.change_description.split('<br>')" :key="'cgd_'+idx"><p>{{ str }}</p></span>
                      </div>

                    </div>
                  </div>
                  <!-- end list group -->


                </div>
              </div>
              <div class="d-flex chat-input-section align-items-start pt-3">
                <div class="flex-shrink-0 d-none d-sm-block me-3">
                  <img
                    src="@/assets/images/users/avatar-4.jpg"
                    alt=""
                    class="avatar-sm rounded-circle"
                  />
                </div>
                <div class="flex-grow-1">
                  <div class="position-relative d-flex align-items-start">
                    <input
                      type="text"
                      class="form-control chat-input"
                      placeholder="Some text value..."
                    />
                    <div class="chat-input-links d-flex align-items-start">
                      <button
                        type="button"
                        class="btn btn-link text-muted"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Emoji"
                        aria-label="Emoji"
                      >
                        <i class="uil uil-smile"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-link text-muted"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Attachments"
                        aria-label="Attachments"
                      >
                        <i class="uil uil-paperclip"></i>
                      </button>
                      <button type="button" class="btn btn-primary">
                        <i class="uil uil-message"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
          </b-tab>
        </b-tabs>
        <!-- Nav tabs -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
