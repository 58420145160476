<script>


/**
 * Property component
 */

 import {
  required,
} from "vuelidate/lib/validators";

import Choices from "choices.js";

import {MiscApi} from '@/utils/misc'
import {place_auto_complete} from '@/api/misc'


export default {

    emits: ['confirm', 'cancel'],

    props: ['property'],
    components: {
        
    },

    validations() {
       return {
            property: {
                address : {required},
                city_name: { required },
                province_name: { required },
                postal_code: { required },
                pid1 : {required},
                pid2 : {required},
                pid3 : {required},
            },
        }
    },

    

    data() {
      
        return {
            submitted : false,
            cities: [],
            provinces: [],
            items : [],
            address_choice: {}
        }
       
    },
    methods: {
        formSubmit() {
            console.log(this.property)
            this.submitted = true;     
            this.$v.$touch();
         
            if (this.$v.$error == false) {  
                this.$emit('confirm', this.property)
            }
        },

        onSearched(e) {
            place_auto_complete({ "str": e.detail.value}).then((res) => {
                this.items = []
               
                res.data.map((d) => {
                    this.items.push({
                        label: d.street,
                        value: d.street,
                        addr  : d
                    })
                })

                if (this.items.length <= 0) {
                    this.items.push({
                        label: e.detail.value,
                        value: e.detail.value,
                        addr  : {
                            city : '',
                            province: '',
                            country : '',
                            postal_code :'',
                        }
                    })
                }
                this.address_choice.clearChoices()
                this.address_choice.setChoices(this.items)
                this.address_choice.showDropdown()
            })
        },

        onChanged(e) {
            let item = this.items.find((i) => i.value == e.detail.value)
         
            this.property.city_name = item.addr.city.trim()
            this.property.province_name = item.addr.province.trim()
            this.property.country = item.addr.country.trim()
            this.property.postal_code = item.addr.postal_code.trim()
        }
    },

    created() {
        let city_list = MiscApi.cityList()
        let province_list =  MiscApi.queryProvinceList()

        city_list.map( (c) =>{
            this.cities.push({
                value : c.id,
                label : c.city_name,
                item :c
            })
        })
       
        province_list.map( (c) =>{
            this.provinces.push({
                value : c.id,
                label : c.en_name,
                item :c
            })
        })



        
    },

    mounted() {

       

        // let element =document.getElementById('city_option')
        // new Choices(element, {
        //     placeholderValue: "This is a placeholder set in the config",
        //     searchPlaceholderValue: "search by name",
        // }).setChoices(this.cities);


        // element =document.getElementById('province_option')
        // new Choices(element, {
        //     placeholderValue: "This is a placeholder set in the config",
        //     searchPlaceholderValue: "search by name",
        // }).setChoices(this.provinces);

        this.address_choice = new Choices("#address", {
            placeholderValue: "input address to search",
            searchPlaceholderValue: "search by name",
            searchEnabled: true,
            editItems: true,
        });

    }

};
</script>

<template>
    <div class="card">
        <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Property Information</h4>
        </div>
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit" ref="form">
                <div class="row">

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom02">unit</label>
                            <input id="validationCustom02" v-model="property.unit" type="text"
                                class="form-control" placeholder="Unit"   />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom03">address1</label>
                            <select id="address" v-model="property.address" type="text"
                              
                                @search="onSearched"    @change="onChanged"        
                                :class="{
                                    'is-invalid': submitted && $v.property.address.$error,
                                }" >
                            </select>
                                <div v-if="submitted && $v.property.address.$error" class="invalid-feedback">
                                <span v-if="!$v.property.address.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="mb-3">
                            <label
                            for="city_option"
                            class="form-label font-size-13 text-muted"
                            >City</label
                            >
                            <input v-model="property.city_name" type="text" class="form-control"
                                placeholder="City" required :class="{
                                    'is-invalid': submitted && $v.property.city_name.$error,
                                }" />

                            <div v-if="submitted && $v.property.city_name.$error" class="invalid-feedback">
                                <span v-if="!$v.property.city_name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label
                            for="province_option"
                            class="form-label font-size-13 text-muted"
                            >Province</label >

                            <input v-model="property.province_name" type="text" class="form-control"
                                placeholder="Provice Name" :class="{
                                    'is-invalid': submitted && $v.property.province_name.$error,
                                }" required />

                            <div v-if="submitted && $v.property.province_name.$error" class="invalid-feedback">
                                <span v-if="!$v.property.province_name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom05">Postal Code</label>
                            <input  v-model="property.postal_code" type="text" class="form-control"
                                placeholder="Postal Code"  required :class="{
                                    'is-invalid': submitted && $v.property.postal_code.$error,
                                }" />
                            <div v-if="submitted && $v.property.postal_code.$error" class="invalid-feedback">
                                <span v-if="!$v.property.postal_code.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">PID</label>
                            <div class="row">
                                <div class="col-md-3">
                                    <input id="validationCustom01" v-model="property.pid1" type="text"
                                    class="form-control" placeholder="PID" :class="{
                                        'is-invalid': submitted && $v.property.pid1.$error,
                                    }" />
                                    <div v-if="submitted && $v.property.pid1.$error" class="invalid-feedback">
                                        <span v-if="!$v.property.pid1.required">This value is required.</span>
                                    </div>
                                </div>
                               
                                <div class="col-md-3">
                                    <input id="validationCustom01" v-model="property.pid2" type="text"
                                    class="form-control" placeholder="PID"  :class="{
                                        'is-invalid': submitted && $v.property.pid2.$error,
                                    }" />
                                    <div v-if="submitted && $v.property.pid2.$error" class="invalid-feedback">
                                        <span v-if="!$v.property.pid2.required">This value is required.</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <input id="validationCustom01" v-model="property.pid3" type="text"
                                    class="form-control" placeholder="PID"  :class="{
                                        'is-invalid': submitted && $v.property.pid3.$error,
                                    }" />
                                    <div v-if="submitted && $v.property.pid3.$error" class="invalid-feedback">
                                        <span v-if="!$v.property.pid3.required">This value is required.</span>
                                    </div>
                                </div>
                               
                                
                            </div>

                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-xl-12">
                        <h5 class="font-size-14 mb-3">Legal Description</h5>
                        <div class="d-flex flex-wrap gap-2 col-md-12">
                                <textarea
                                    class="form-control"
                                    placeholder="Leave a comment here"
                                    v-model="property.description"
                                    id="floatingTextarea2"
                                    style="height: 100px"
                                ></textarea>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>