var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',{ref:"form",staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom02"}},[_vm._v("unit")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.unit),expression:"property.unit"}],staticClass:"form-control",attrs:{"id":"validationCustom02","type":"text","placeholder":"Unit"},domProps:{"value":(_vm.property.unit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.property, "unit", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom03"}},[_vm._v("address1")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.address),expression:"property.address"}],class:{
                                'is-invalid': _vm.submitted && _vm.$v.property.address.$error,
                            },attrs:{"id":"address","type":"text"},on:{"search":_vm.onSearched,"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.property, "address", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.onChanged]}}),(_vm.submitted && _vm.$v.property.address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.property.address.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-4"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label font-size-13 text-muted",attrs:{"for":"city_option"}},[_vm._v("City")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.city_name),expression:"property.city_name"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.submitted && _vm.$v.property.city_name.$error,
                            },attrs:{"type":"text","placeholder":"City","required":""},domProps:{"value":(_vm.property.city_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.property, "city_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.property.city_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.property.city_name.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label font-size-13 text-muted",attrs:{"for":"province_option"}},[_vm._v("Province")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.province_name),expression:"property.province_name"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.submitted && _vm.$v.property.province_name.$error,
                            },attrs:{"type":"text","placeholder":"Provice Name","required":""},domProps:{"value":(_vm.property.province_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.property, "province_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.property.province_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.property.province_name.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom05"}},[_vm._v("Postal Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.postal_code),expression:"property.postal_code"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.submitted && _vm.$v.property.postal_code.$error,
                            },attrs:{"type":"text","placeholder":"Postal Code","required":""},domProps:{"value":(_vm.property.postal_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.property, "postal_code", $event.target.value)}}}),(_vm.submitted && _vm.$v.property.postal_code.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.property.postal_code.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom01"}},[_vm._v("PID")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.pid1),expression:"property.pid1"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.submitted && _vm.$v.property.pid1.$error,
                                },attrs:{"id":"validationCustom01","type":"text","placeholder":"PID"},domProps:{"value":(_vm.property.pid1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.property, "pid1", $event.target.value)}}}),(_vm.submitted && _vm.$v.property.pid1.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.property.pid1.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-md-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.pid2),expression:"property.pid2"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.submitted && _vm.$v.property.pid2.$error,
                                },attrs:{"id":"validationCustom01","type":"text","placeholder":"PID"},domProps:{"value":(_vm.property.pid2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.property, "pid2", $event.target.value)}}}),(_vm.submitted && _vm.$v.property.pid2.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.property.pid2.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-md-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.pid3),expression:"property.pid3"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.submitted && _vm.$v.property.pid3.$error,
                                },attrs:{"id":"validationCustom01","type":"text","placeholder":"PID"},domProps:{"value":(_vm.property.pid3)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.property, "pid3", $event.target.value)}}}),(_vm.submitted && _vm.$v.property.pid3.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.property.pid3.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('h5',{staticClass:"font-size-14 mb-3"},[_vm._v("Legal Description")]),_c('div',{staticClass:"d-flex flex-wrap gap-2 col-md-12"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.description),expression:"property.description"}],staticClass:"form-control",staticStyle:{"height":"100px"},attrs:{"placeholder":"Leave a comment here","id":"floatingTextarea2"},domProps:{"value":(_vm.property.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.property, "description", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex flex-wrap gap-2"},[_c('b-button',{attrs:{"variant":"secondary","type":"button"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Save")])],1)]),_c('div',{staticClass:"col-md-4"})])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header justify-content-between d-flex align-items-center"},[_c('h4',{staticClass:"card-title"},[_vm._v("Property Information")])])}]

export { render, staticRenderFns }