<script>


/**
 * Seller component
 */

 import {
  required,
} from "vuelidate/lib/validators";

import Choices from "choices.js";

import {MiscApi} from '@/utils/misc'


export default {

    emits: ['confirm', 'cancel'],

    props: ['seller'],
    components: {
        
    },

    validations: {
        seller: {
            seller_first_name: { required },
            seller_last_name: { required },
            address: { required },
            city_id: { required },
            province_id: { required },
            postal_code: { required },
        },
    },

    

    data() {
      
        return {
            submitted : false,
            cities: [],
            provinces: [],
        }
       
    },
    methods: {
        formSubmit() {
            this.submitted = true;     
            this.$v.$touch();     
            if (this.$v.$error == false) {     
                this.$emit('confirm', this.seller)
            }
        },
    },

    created() {
        let city_list = MiscApi.cityList()
        let province_list =  MiscApi.queryProvinceList()

        city_list.map( (c) =>{
            this.cities.push({
                value : c.id,
                label : c.city_name,
                item :c
            })
        })
       
        province_list.map( (c) =>{
            this.provinces.push({
                value : c.id,
                label : c.en_name,
                item :c
            })
        })
    },

    mounted() {

       

        let element =document.getElementById('city_option')
        new Choices(element, {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        }).setChoices(this.cities);


        element =document.getElementById('province_option')
        new Choices(element, {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        }).setChoices(this.provinces);

    }

};
</script>

<template>
    <div class="card">
        <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Seller Information</h4>
        </div>
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">First name</label>
                            <input id="validationCustom01" v-model="seller.seller_first_name" type="text"
                                class="form-control" placeholder="First name" value="Mark" :class="{
                                    'is-invalid': submitted && $v.seller.seller_first_name.$error,
                                }" />
                            <div v-if="submitted && $v.seller.seller_first_name.$error" class="invalid-feedback">
                                <span v-if="!$v.seller.seller_first_name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom02">Last name</label>
                            <input id="validationCustom02" v-model="seller.seller_last_name" type="text"
                                class="form-control" placeholder="Last name" value="" :class="{
                                    'is-invalid': submitted && $v.seller.seller_last_name.$error,
                                }" />
                            <div v-if="submitted && $v.seller.seller_last_name.$error" class="invalid-feedback">
                                <span v-if="!$v.seller.seller_last_name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom03">Unit</label>
                            <input id="validationCustom03" v-model="seller.unit" type="text" class="form-control"
                                placeholder="Unit"  />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom04">Address</label>
                            <input id="validationCustom04" v-model="seller.address" type="text" class="form-control"
                                placeholder="Address"  :class="{
                                    'is-invalid': submitted && $v.seller.address.$error,
                                }" />
                                <div v-if="submitted && $v.seller.address.$error" class="invalid-feedback">
                                <span v-if="!$v.seller.address.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="mb-3">
                            <label
                            for="city_option"
                            class="form-label font-size-13 text-muted"
                            >City</label
                            >
                            <select
                            class="form-control"
                            data-trigger
                            v-model="seller.city_id"
                            name="city_option"
                            id="city_option"
                            :class="{
                                    'is-invalid': submitted && $v.seller.city_id.$error,
                                }"
                            >
                            <option value=""></option>
                            </select>
                            <div v-if="submitted && $v.seller.city_id.$error" class="invalid-feedback">
                                <span v-if="!$v.seller.city_id.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label
                            for="province_option"
                            class="form-label font-size-13 text-muted"
                            >Province</label >
                            <select  class="form-control"  data-trigger
                            v-model="seller.province_id"
                            name="province_option"
                            id="province_option"
                            :class="{
                                    'is-invalid': submitted && $v.seller.province_id.$error,
                                }"
                            >
                            <option value=""></option>
                            </select>
                            <div v-if="submitted && $v.seller.province_id.$error" class="invalid-feedback">
                                <span v-if="!$v.seller.province_id.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom05">Postal Code</label>
                            <input id="validationCustom05" v-model="seller.postal_code" type="text" class="form-control"
                                placeholder="Zip" :class="{
                                    'is-invalid': submitted && $v.seller.postal_code.$error,
                                }" />
                            <div v-if="submitted && $v.seller.postal_code.$error" class="invalid-feedback">
                                <span v-if="!$v.seller.postal_code.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom03">Phone</label>
                            <input id="validationCustom03" v-model="seller.phone" type="text" class="form-control"
                                placeholder="Phone"  />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom04">Email</label>
                            <input id="validationCustom04" v-model="seller.email" type="text" class="form-control"
                                placeholder="Email"  />

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom05">ITN</label>
                            <input id="validationCustom05" v-model="seller.seller_company_sin" type="text" class="form-control"
                                placeholder="ITN/SIN"  />
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-xl-6">
                        <h5 class="font-size-14 mb-3">Is Canada</h5>
                        <div class="d-flex flex-wrap gap-2">
                            <input type="checkbox" id="is_canada" switch="bool" v-model="seller.is_canada" checked />
                            <label
                                for="is_canada"
                                data-on-label="Yes"
                                data-off-label="No"
                                class="mb-0"
                            ></label>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>